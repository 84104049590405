import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/navigation";

import { USER_TYPES } from "@/config/constant";
import sessionConfig from "@/config/session-config";
import { LoginResponse } from "@/types/login";

export default function useAuth() {
  const router = useRouter();
  const queryClient = useQueryClient();
  const redirect_to = "";
  const LoginSuccess = (data: LoginResponse) => {
    if (!data?.user) {
      return;
    }
    queryClient.clear(); // Reset any previously stored cache queries
    queryClient.setQueryData(["current_user"], data.user);

    if (data?.user?.type === USER_TYPES.INTERNAL) {
      return router.replace(
        `/select-store${
          redirect_to ? `?redirect_to=${String(redirect_to)}` : ""
        }`,
      );
    }

    if (!data?.stores?.length) {
      return router.replace("/connect-square");
    }
    const totalStores = data?.stores?.length;
    const activeStore = data?.stores?.find((store) => store.active);
    if (totalStores === 1 && activeStore) {
      const storeId = activeStore?.store_id;
      localStorage.setItem(sessionConfig.activeStore, activeStore?.store_id);
      queryClient.setQueryData(["store", { storeId }], activeStore);
      router.push("/dashboard/calendar");
      return router.refresh();
    }
    // when user has only one store and the store is not active
    if (totalStores === 1) {
      return router.replace("/connect-square");
    }

    router.push("/select-store");
    return router.refresh();
  };

  return { LoginSuccess };
}
